'use strict';

var app = angular.module('limaApp');

app.controller('NavbarCtrl', ['$scope', '$location', '$translate', 'Auth', 'Modal', '$timeout', '$localStorage', 'LimaRedirect','limaOptionService',
function ($scope, $location, $translate, Auth, Modal, $timeout, $localStorage, LimaRedirect, limaOptionService) {

  $scope.isCollapsed = true;
  $scope.isLoggedIn = Auth.isLoggedIn;
  $scope.isAdmin = Auth.isAdmin;
  $scope.getCurrentUser = Auth.getCurrentUser;
  $scope.hasFunction = Auth.hasFunction;


  $scope.storage = (function () {
    var key = 'limaApp:NavbarCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};
    var store = function () {
      $localStorage[key] = storage;
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();

  //$scope.storage.modeDetail = false;


  $scope.changeMode = function() {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "モードを切り替えます。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) return;
      $scope.storage.modeDetail = !$scope.storage.modeDetail;

      });

    //$timeout(function(){$scope.$apply()});
  }

  $scope.logout = function() {
    Auth.logout();
    LimaRedirect.go({path:'/login', returnPath:'/', clearCache:true});
  };

  $scope.getDisplayName = function () {
    var u = $scope.getCurrentUser();
    return {name: u.displayName || u.name};
  };

  $scope.greetings = function () {
    return $translate.instant('NAVIGATION.GREETINGS') + $scope.getDisplayName().name + $translate.instant('NAVIGATION.SAN');
  };

  $scope.isActive = function(route) {
    if (!_.isArray(route)) route = [ route ];
    return _.includes(route, $location.path());
  };

  Auth.systemProperties().then(function (data) {
    $scope.brand = {text: _.get(data, 'setting.brand.text') || 'LIMA'};
  });

  $scope.enableMailSupport = false;
  $scope.enableDeviceGroup = false;
  limaOptionService.getOptionService(function(result) {
    var find = _.find(result, function(op){
      return op.name === 'DeviceGroup' ? true : false;
    });
    if (find) {
      $scope.enableDeviceGroup = true;
    }
    find = _.find(result, function(op){
      return ((op.name === 'Support_By_Mail') || (op.name === 'Support_By_Tel_and_Mail')) ? true : false;

    });
    if (find) {
      $scope.enableMailSupport = true;
    }
  });


}]);
