'use strict';

import './app/app.css';
import './app/app.js';

//トップページ
import './app/toppage/toppage.controller.js';
import './app/toppage/toppage.js';

//登録企業の登録（社外、社内　両用）
import './app/company/register.controller.js';
import './app/company/register.js';
import './app/company/register.css';

//登録の入り口（社外用）
import './app/company/user/userLogin.controller.js';
import './app/company/user/userLogin.js';

//クレジットカードの決済前処理、オプション変更、カード関連処理
import './app/creditcard/ccpayment.controller.js';
import './app/creditcard/ccpayment.js';
import './app/creditcard/ccpayment.css';
//クレジットカードの決済後のありがとう処理
import './app/creditcard/ccpresult.controller.js';
import './app/creditcard/ccpresult.js';
import './app/creditcard/ccpresult.css';


import './components/utils.js';
import './components/auth/auth.service.js';
import './components/auth/user.service.js';
import './components/modal/modal.css';
import './components/modal/modal.service.js';
import './components/navbar/navbar.controller.js';
import './components/navbar/navbar.css';
import './components/socket/socket.service.js';

