'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('creditCardPaymentResult', {
    url: '/ccpresult',
    templateUrl: 'app/creditcard/ccpresult.html',
    authenticate: true,
    controller: 'CreditCardPaymentResultCtl',
    title: 'クレジットカードの処理'
  })
}]);
