'use strict';

var app = angular.module('limaApp');

// カードでの契約ができたときに呼ばれる
/*
  result (1) 1=成功 2=失敗
  pid    (7-9) 決済番号
  sod    (1-50) 店舗オーダ番号
  任意の追加フィールド
  ex)ご指定URL)?result=1&pid=0000000&sod=XXXXXXX&uniquefield=1234
*/
app.controller('CreditCardPaymentResultCtl', ['$scope', '$translate', '$location', '$http', '$window', '$timeout',
function ($scope, $translate, $location, $http, $window, $timeout) {

  $scope.pageType = 'creditCardPaymentResultInfo';
  $scope.result = $location.search()["result"];
  if (_.isUndefined($scope.result) ) {
    $scope.result = '0';
  }
  $scope.pid = $location.search()['pid'];
  $scope.sod = $location.search()['sod'];
  $scope.companyId = $scope.sod;  //sodにcompanyIdが設定されている
  if (_.isUndefined($scope.companyId) ) {
    $scope.companyId = $location.search()['companyId'];
  }
  $scope.name = ""; //登録者名
  $scope.requestType = $location.search()['requestType'];

  $scope.user = {};
  $scope.encToken= "";
  $scope.message="";
  $http.get('/api/v1/company/login').success(function (result) {
    $scope.encToken = encodeURIComponent(result.token);
    $scope.user = result.user;
    if ( $scope.requestType === 'ACSTOP' ) {
      initResult(false);
      $scope.message=$translate.instant('CreditCard.Result.Unregist');
    }
    else if ( $scope.requestType === 'CARDCG' ) { // カード変更
      initResult(true); //カード変更が発生するのは契約中なので
      $scope.message=$translate.instant('CreditCard.Result.CardChange');
    }
    else {
      initResult($scope.result === '1' ? true : false );  // 1: 成功　2: 失敗
      $scope.message=$translate.instant('CreditCard.Result.Regist');
    }
  }).error(function (err) {
  });


  function initResult(paidup_value) {
    $http({
      url: '/api/v1/creditcard/paidup/' + $scope.companyId + '?access_token=' + $scope.encToken,
      method: 'PUT',
      params: {
        value  : paidup_value,
      }
    })
    .success(function(res){
      getCompanyInfo();
    })
    .error(function(){
      getCompanyInfo();
    });
  }
  // -------------------------------------------------------------------
  //
  // -------------------------------------------------------------------
  function getCompanyInfo() {
    $http({
      url: '/api/v1/company/'+ $scope.companyId ,
      method: 'GET',
    })
    .success(function(info){
      $timeout( function(){
        $scope.name = info.name;
      });
    })
    .error(function(){
      $timeout( function(){
        $scope.name = "";
      });
    });
  }
    

  $scope.cancel = function() {
    //window.history.back();
  }

  $scope.toLoginPage = function() {
    $http({
      url: '/api/v1/creditcard/loginpage/' + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(json){
      $timeout(function(){
        if ( $scope.result === '0' ) {
          $window.location.href=json.loginPage + 'logout';
        }
        else {
          $window.location.href=json.loginPage;
        }
      });
    })
    .error(function(data){
    });
  
  }


}]);
