'use strict';

var app = angular.module('limaApp');

app.controller('CreditCardPaymentCtl', ['$scope', '$translate', 'Auth', '$http', '$location', '$timeout', '$window', 'Modal',
function ($scope, $translate, Auth, $http, $location, $timeout, $window, Modal ) {
  $scope.user = {};
  $scope.encToken= "";
  $http.get('/api/v1/company/login').success(function (result) {
    $scope.encToken = encodeURIComponent(result.token);
    $scope.user = result.user
    initCompanyId();
  }).error(function (err) {
  });
  $scope.companyId = $location.$$search.id;

  $scope.errors = {};

  $scope.pageType = '';
  $scope.registForCorp = false;
  $scope.showChangeBtn = true; //オプションの変更ボタンを表示するかどうか
  $scope.showChangeAlertMessage = false; //オプション変更時の警告メッセージを表示するかどうか
  $scope.creditCardStopped = false; //オプション変更時、クレジットカードを止めたかどうか

  //- ===========================
  //- オプションテーブル関連。pugと連携するものはここに定義
  const _selectOptionLibrary = require('../company/_selectOptionLibrary');
  $scope.optlib = new _selectOptionLibrary($scope);
  $scope.optionServiceList= undefined;
  $scope.totalPrices = 0;       // 費用合計
  $scope.initialPrices = $scope.optlib.getInitialPrices();  // 契約手数料
  $scope.changedFlag = false;   // 一度でもchangeDeviceChoiseが呼ばれたらtrueにする
  $scope.changeDeviceChoise = function (optName, optChecked) { 
    $scope.changedFlag = true;   // 一度でもchangeDeviceChoiseが呼ばれたらtrueにする
    return $scope.optlib.changeDeviceChoise($scope, optName, optChecked);
  }
  //- ===========================

  /**
   * companyIdの処理
   */
  function initCompanyId() {
    if (_.isUndefined( $scope.companyId ) ) {
      $http({
        url: '/api/v1/creditcard/dec/' + encodeURIComponent($location.$$search.cid) + + '?access_token=' + $scope.encToken,
        method: 'GET',
      })
      .success(function(result){
        $scope.companyId = result.companyId;

        //-----------
        // パラメータを API側から取得する
        if ( !_.isUndefined(result.storeId) ) {
          $scope.UnivaPaycast.sid = result.storeId;
        }
        if ( !_.isUndefined(result.autoPayMode) ) {// 自動課金周期：21=日付指定
          $scope.UnivaPaycast.capture.actp1 = result.autoPayMode;
        } 
        if ( !_.isUndefined(result.autoPayDay) ) {// 自動課金日付: x日付
          $scope.UnivaPaycast.capture.acdc1 = result.autoPayDay; 
        } 
        if ( !_.isUndefined(result.autoPayInterval) ) {// 自動課金間隔： (指定値）x　ヶ月)
          $scope.UnivaPaycast.capture.acmc1 = result.autoPayInterval;
        } 
        if ( !_.isUndefined(result.autoPayNum) ) { // 自動課金回数： 0=無制限
          $scope.UnivaPaycast.capture.acrm1 = result.autoPayNum;
        }
        if ( !_.isUndefined(result.autoPayRetryNum) ) {// 自動課金リトライ回数
          $scope.UnivaPaycast.capture.acrt1 = result.autoPayRetryNum;
        } 
        if ( !_.isUndefined(result.autoPayRetryStep) ) {//自動課金リトライ間隔
          $scope.UnivaPaycast.capture.acrd1 = result.autoPayRetryStep;
        }　
        //-----------

        $scope.UnivaPaycast.redirectCode = result.creditRedirectCode;
        $scope.UnivaPaycast.sod = $scope.companyId; //UnivaPaycastの情報 sodにはcompanyIdを設定する
  
        initCreditInfo();
        $scope.optlib.getListOfOptionService($scope, $http, $timeout, $scope.encToken, function() {
          setPriceToUnivaPaycastInfo();
        }) ;
      });
    }
    else {
      initCreditInfo();
      $scope.optlib.getListOfOptionService($scope, $http, $timeout, $scope.encToken, function() {
        setPriceToUnivaPaycastInfo();
      }) ;
    }
  }
  function setPriceToUnivaPaycastInfo() {
    $http({
      url: '/api/v1/creditcard/'+ $scope.companyId + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(result){
      $timeout(function(){
        if (result.errorcode.length ) {
          //自動課金に失敗した時、契約手数料＝＝失敗分というメッセージを出す
          $scope.showChangeBtn = false;
          $scope.optlib.additionalErr = result.errorcode; //エラーコード表示用
          $scope.optlib.additionalMsg = $translate.instant('CreditCard.Payment.Message.ErrorPay');
          $scope.initialPrices = $scope.totalPrices;  // 自動課金ができなかった料金
        }
        else if (result.paid_up ) { //既に決済済み
          if ($scope.nMode ==="change") {
            //オプション変更時以外は表示することに#6801
            $scope.showChangeBtn = false;
          }
          $scope.optlib.additionalMsg = ""; 
          $scope.initialPrices = $scope.optlib.getChangePrices();  // 手数料
        }
        //- クレジットカード支払いのとき設定する値
        $scope.UnivaPaycast.capture.totalPricesWithTax   = $scope.optlib.withTax($scope.totalPrices);       // 費用合計（月額税込み）
        $scope.UnivaPaycast.capture.initialPricesWithTax = $scope.optlib.withTax($scope.initialPrices);   // 契約手数料（税込み）
      });
    })
    .error(function(result){
      $timeout(function(){
        //- クレジットカード支払いのとき設定する値
        $scope.UnivaPaycast.capture.totalPricesWithTax   = $scope.optlib.withTax($scope.totalPrices);       // 費用合計（月額税込み）
        $scope.UnivaPaycast.capture.initialPricesWithTax = $scope.optlib.withTax($scope.initialPrices);   // 契約手数料（税込み）
      });
    });
  }

  /**
   * UnivaPaycastに送る情報関連
   */
  $scope.UnivaPaycast = {
    sid : '103985',  // 店舗ID
    svid : '1',      // サービス種別：必ず「1」を指定
    ptype : '3',     // 処理種別　3=リンク方式
    cmd : '2',    //更新、停止時の検索条件（ 2=初回決済番号＋自動課金番号）
    sod : $scope.companyId,  //店舗オーダ番号（companyIdを設定する）
    sisf1: '0',   //送料
    productName : encodeURIComponent("つながるサイネージ"),
    redirectCode : undefined,
    product : 'tsunagaru',
    pid : '0',
    acid1: '0',   //初回決済時に商品1に対し当社決済システムが発行した自動課金番号
    //クレジット決済時に使用
    capture: {
      job: 'CAPTURE',
      rt: '1',  // 決済結果の返信方法:1=キックバック
      totalPricesWithTax : '0',       // 費用合計（月額税込み）
      initialPricesWithTax : '0',   // 契約手数料（税込み）
      acrt1: '3',   //商品1に対する自動課金リトライ回数 未指定時は「3」回
      acrd1: '3',   //商品1に対する自動課金リトライ周期: (指定値)✕ 日 未指定時は以下のデフォルト値
                    //自動課金周期が「2,20,22」=1日、「3」=3日、「それ以外」=7日 (毎週同曜日)
  　　/* 正式運用 */
      actp1: '21',　// 自動課金周期：21=日付指定 , 20=日数指定
      acdc1: '1',  // 自動課金日付: x日付 , acctp1==20の時：x日周期
      acmc1: '1',  // 自動課金間隔： (指定値）x　ヶ月)
      acrm1: '0',  // 自動課金回数： 0=無制限
    },
    //クレジット決済時手数料なしの時
    addac: {
      job: 'ADDAC', //ADDAC=有効性チェック型自動課金登録  * 本番運用。テストカード番号では有効性がNGとなる。
//      job: 'ADDAC2', //ADDAC2=カード登録型自動課金登録 ＊　本番使用不可。番号のチェックだけ。
    },
    //クレジットカード変更時に使用
    cardChange: {
      job: 'CARDCG',
      rt: '2',  // 決済結果の返信方法:2以外指定・省略・リンク方式では返信なし
    },
    //契約解除時に使用
    stop: {
      job: 'ACSTOP',
      rt: '1',  // 決済結果の返信方法:1=キックバック　リンク方式ではキックバックのみ
    },
    //契約解除時に使用(GW方式)
    stop_gw: {
      job: 'ACSTOP',
      ptype:'1' , // 処理種別：　1=ゲートウェイ決済
      rt:'2' ,    // 決済結果の返信方法：　2=レスポンス
      cmd:'2' ,   // 検索条件：2=初回決済番号＋自動課金番号
    }
  }

  /**
   * 契約済のユーザが変更など行うために呼び出された場合
   */
  function initCreditInfo() { 
    $scope.nMode = $location.$$search.mode; //
    if ($scope.nMode ==="payment" ) {
      $scope.showChangeAlertMessage = false;  //新規契約時にはメッセージを出さないため「確認済み」とする
      $timeout(function(){
        $scope.pageType = 'SelectedOptionsConfirm';
      });
    }
    else {
      $scope.showChangeAlertMessage = true;  //新規契約時にはメッセージを出さないため「確認済み」とする
      $http({
        url: '/api/v1/creditcard/'+ $scope.companyId  + '?access_token=' + $scope.encToken,
        method: 'GET',
      })
      .success(function(result){
        //契約ID
        $scope.UnivaPaycast.pid = result.init_pid;
        $scope.UnivaPaycast.acid1 = result.init_acid1; //自動課金番号
        if ($scope.nMode ==='change') {
          $timeout(function(){
            if (result.errorcode.length === 0 ) {
              $scope.pageType = 'ChangeOptions';
            }
            else {
              $scope.pageType = 'SelectedOptionsConfirm';
            }
          });
        }
        else if ($scope.nMode ==='stop') {
          $scope.suspendOrStopPayment('disable', function(){
            $timeout(function(){
              toStopResult();
            });
          });
        }
        else if ($scope.nMode ==="cardchange" ) {
          $scope.toCardChange();
        }
      })
      .error(function(result){
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('CreditCard.Message.CannotGetInfo'),
          modalClass:'modal-warning'
        });
        $timeout(function(){
          $scope.pageType = '';
        });
      });
    }
  }

  /**
   * カードを変更するページ（UnivaPaycast）に遷移する
   */
  $scope.toCardChange= function() {
    // フォームの生成
    var action='/api/v1/creditcard/tounivapay/';
    var form = document.createElement("form");
    form.setAttribute("action", action);
    form.setAttribute("method", "post");
    form.style.display = "none";
    document.body.appendChild(form);
    // パラメタの設定
    input_date(form, 'hidden', 'sid',  $scope.UnivaPaycast.sid );
    input_date(form, 'hidden', 'svid', $scope.UnivaPaycast.svid );
    input_date(form, 'hidden', 'ptype',$scope.UnivaPaycast.ptype );
    input_date(form, 'hidden', 'job',  $scope.UnivaPaycast.cardChange.job );
    input_date(form, 'hidden', 'rt',   $scope.UnivaPaycast.cardChange.rt );
    input_date(form, 'hidden', 'cmd',  $scope.UnivaPaycast.cmd );
    input_date(form, 'hidden', 'pid',  $scope.UnivaPaycast.pid  );
    input_date(form, 'hidden', 'acid1',  $scope.UnivaPaycast.acid1  );
    input_date(form, 'hidden', 'sucd', $scope.UnivaPaycast.redirectCode );
    input_date(form, 'hidden', 'companyId', $scope.companyId );
    input_date(form, 'hidden', 'product',  $scope.UnivaPaycast.product );
    // submit
    form.submit();
  }

  function input_date(form, paramType, paramName, value ) {
    var input = document.createElement('input');
    input.setAttribute('type', paramType);
    input.setAttribute('name', paramName);
    input.setAttribute('value', value);
    form.appendChild(input);
  }

  $scope.canChangeOption = function() {
    if ( $scope.UnivaPaycast.acid1 === "0" ||  $scope.UnivaPaycast.acid1.length === 0 ) {
      return false;
    }
    return true;
  }

  //--------------------------------------------
  function doChangeOption(companyId, callback) {
    //var info = $scope.regCompany;
    var postdata = {
      options : $scope.optionServiceList,
    };
  
    var httpParam = {
      url: '/api/v1/company/options/update/' + companyId + '?access_token=' + $scope.encToken,
      method: 'PUT',
      data: postdata
    };
    $http(httpParam).success(function(result) {
      callback(result);
    });
  }
  
  //--------------------------------------------
  // オプション変更ページへ
  //--------------------------------------------
  $scope.toChangeOptionPage = function() {
    $timeout(function(){
      $scope.pageType = 'ChangeOptions';
    });
  }

  //--------------------------------------------
  // オプション変更時
  //--------------------------------------------
  $scope.toChangeOptionConfirm = function() {
    if ( $scope.showChangeAlertMessage === false ) {
      doChangeOption($scope.companyId, function(result ) {
        //- クレジットカード支払いのとき設定する値
        $scope.UnivaPaycast.capture.totalPricesWithTax   = $scope.optlib.withTax($scope.totalPrices);       // 費用合計（月額税込み）
        $scope.UnivaPaycast.capture.initialPricesWithTax = $scope.optlib.withTax($scope.initialPrices);   // 契約手数料（税込み）
        $timeout(function(){
          $scope.pageType = 'SelectedOptionsConfirm';
        });
      });
      return;
    }
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: $translate.instant('CreditCard.Payment.Message.SuspendConfirm'),
      btnClassYes: 'btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (yes) {
        $scope.suspendOrStopPayment('suspend', function(){
          doChangeOption($scope.companyId, function(result ) {
            setPriceToUnivaPaycastInfo()
            $timeout(function(){
              $scope.pageType = 'SelectedOptionsConfirm';
            });
          });
        });
      }
    });
  }

  //--------------------------------------------
  //
  //--------------------------------------------
  $scope.suspendOrStopPayment = function(cmd, callback ) {
    if ( $scope.creditCardStopped === true ) {
      callback();
      return; //停止済み
    }
    var url = '/api/v1/creditcard' + '/' + cmd + '/';
    $http({
      url: url + $scope.companyId  + '?access_token=' + $scope.encToken,
      method: 'GET',
      params: {
        sid  : $scope.UnivaPaycast.sid,
        svid : $scope.UnivaPaycast.svid,
        ptype: $scope.UnivaPaycast.stop_gw.ptype,// 処理種別：　1=ゲートウェイ決済
        job  : $scope.UnivaPaycast.stop_gw.job,//' + req.body.job + '&'
        rt   : $scope.UnivaPaycast.stop_gw.rt,//' + '2' + '&'     // 決済結果の返信方法：　1=キックバッグ
        cmd  : $scope.UnivaPaycast.stop_gw.cmd,     // 検索条件：2=初回決済番号＋自動課金番号
        // pid: //呼び出し先で設定
        // acid: 
        companyId: $scope.companyId,
        product: $scope.UnivaPaycast.product
      }
    })
    .success(function(res){
      if ( res.result === 'OK') {
        $scope.creditCardStopped = true;
        if ( cmd === 'suspend' ) {
          Modal.confirm.message({
            title:$translate.instant('COMMON.CONFIRM'),
            html: $translate.instant('CreditCard.Payment.Message.SuspendOK'),
            modalClass:'modal-warning'
          });
        }
        callback();
      }
      else {
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('CreditCard.Payment.Message.SuspendSKIP'),
          modalClass:'modal-warning'
        });
        // 既に処理済みなどの時はこっちにくるのでOKとしておく
        /*
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('CreditCard.Payment.Message.SuspendERR') + res.univaPaycast.ec,
          modalClass:'modal-warning'
        });
        */
        callback();
      }
    })
    .error(function(){
        /*
        Modal.confirm.message({
          title:$translate.instant('COMMON.CONFIRM'),
          html: $translate.instant('CreditCard.Payment.Message.SuspendNG')),
          modalClass:'modal-warning'
        });
        */
       callback();
    });
  }

  /*
   * 解約
   */
  function toStopResult() {
    $http({
      url: '/api/v1/creditcard/enc/' + $scope.companyId + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(result){
      const url = '/ccpresult?cid=' + encodeURIComponent(result.cidtoken) 
                                    + '&access_token=' + $scope.encToken
                                    + '&sod=' + $scope.companyId
                                    + '&requestType=ACSTOP';
      $window.location.href=url ;
    });
  }

  $scope.backToPreviewPage = function() {
    if ( $scope.nMode ==="payment" ) {
      $scope.optlib.getListOfOptionServiceFromCompanyId($scope, $http, $timeout, $scope.encToken, $scope.companyId, function() {
        $timeout(function(){
          $scope.changedFlag = false;   // 一度でもchangeDeviceChoiseが呼ばれたらtrueにする
          $scope.pageType = 'SelectedOptionsConfirm';
        }) ;
      });
    }
    else {
      $http({
        url: '/api/v1/creditcard/loginpage/' + '?access_token=' + $scope.encToken,
        method: 'GET',
      })
      .success(function(json){
        $timeout(function(){
          $window.location.href=json.loginPage + "company/info";
        });
      })
      .error(function(data){
      });
    }
  }

}]);