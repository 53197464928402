'use strict';

var app = angular.module('limaApp');

/*
*/
app.controller('ToppageCtl', ['$scope', '$location', '$http', '$window', '$timeout', 'MediaedgeAdmin',
function ($scope, $location, $http, $window, $timeout, MediaedgeAdmin) {

  $scope.pageType = 'toppageInfo';

  $scope.cancel = function() {
    //window.history.back();
  }
}]);
