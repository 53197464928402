'use strict';

var app = angular.module('limaApp');
var async = require('async');

app.controller('RegisterCtrl', ['$scope', '$translate', 'Auth', '$http', '$location', '$window', '$timeout', 'MediaedgeAdmin', 'Modal',
function ($scope, $translate, Auth, $http, $location, $window, $timeout,MediaedgeAdmin, Modal ) {
  if (typeof $scope.encToken === 'undefined' ) {
    // メディアエッジ社内向けページでは、registIdがSessionStrageに設定されていること（電話番号を暗号化して使用）
    MediaedgeAdmin.checkRegistId($http, function(can, tokenResult) {
      if ( !can ) {
        $window.location.href="/" ;
        return;  
      }
      else {
        $scope.encToken = encodeURIComponent(tokenResult.token);
        $scope.user = tokenResult.user
      }
    });
  }
  $scope.pageType = 'companyInfo';
  $scope.errors = {};
  $scope.registForCorp = false;

  $scope.userinfo = null;
  $scope.isSelectedOptions = false; // オプション選択画面を表示したかどうか（戻っても選択状態を保持する）

  //- ===========================
  //-  オプションテーブル関連。pugと連携するものはここに定義
  const _selectOptionLibrary = require('./_selectOptionLibrary');
  $scope.optlib = new _selectOptionLibrary($scope);
  $scope.optionServiceList= undefined;
  $scope.totalPrices = 0;       // 費用合計
  $scope.initialPrices = $scope.optlib.getInitialPrices();  // 契約手数料
  $scope.changeDeviceChoise = function (optName, optChecked) { return $scope.optlib.changeDeviceChoise($scope, optName, optChecked);  }
  //- ===========================
  $scope.selectPackage = {
    type: 0
  }
  $scope.inputCompanyInfo = {
    companyName: '',
    divisionName: '',
    staffName: '',
    email: '',
    email_chk: ''
  }

  $scope.cancel = function() {
    //window.history.back();
  }

  $scope.nextToOption = function() {
    var msg = checkInputInfo();
    if ( $scope.registForCorp ) {
      msg = msg + $scope.checkInputCorpInfo();
    }
    if (msg.length > 0 ) {
      Modal.confirm.message({
        title:$translate.instant('COMMON.CONFIRM'),
        html:"必須項目の入力が正しくできていません",
        modalClass:'modal-warning'
      });
      return;        
    }
    else {
      isEmailExist(function(exist){
        if ( exist ) {
          //データが取得できたら
          Modal.confirm.yesno({
            title: $translate.instant('COMMON.CONFIRM'),
            html: "このメールアドレスは登録されています。登録処理を続けますか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
            btnClassYes: 'btn-default',
            btnCaptionYes: $translate.instant('COMMON.YES'),
            btnClassNo: 'btn-danger',
            btnCaptionNo: $translate.instant('COMMON.NO'),
            modalClass: 'modal-danger'
          }, function (yes) {
            if (!yes) {
              //存在するときはページ遷移しない
              $scope.pageType = 'companyInfo';
              return;
            }
          });
        }
      });
    }

    $timeout(function(){
      $scope.pageType = 'optionInfo';
      if (!$scope.isSelectedOptions ) {
        $scope.optlib.getListOfOptionService( $scope, $http, $timeout, $scope.encToken, function() {
          $scope.isSelectedOptions = true;
        });
      }
    });
}

  //--------------------------------------------
  // オプションの登録ページ
  //--------------------------------------------
   //option
   $scope.backToRegister = function() {
    $timeout(function(){
      $scope.pageType = 'companyInfo';
    });
  }

  //確認ページ表示
  $scope.nextToResultOption = function() {
    $timeout(function(){
      $scope.pageType = 'optionResultInfo';
    });
  }


  //--------------------------------------------
  // オプションの確認ページ
  //--------------------------------------------
  //オプション選択ページに戻る
  $scope.backToOption = function() {
    $timeout(function(){
      $scope.pageType = 'optionInfo';
    });
  }

  $scope.nextToRegist = function() {
    if ( $scope.registForCorp ) {
      doRegisterCompany($scope.selectedYearChoise.value * 12, $scope.mediaedgeStaffChoise.value );
    }
    else {
      doRegisterCompany(1, "");
    }
    $timeout(function(){
      $scope.pageType = 'registedUserInfo';
    });
  }

  //--------------------------------------------
  // 企業を登録する
  //--------------------------------------------
  function doRegisterCompany(month, meStaffName ) {
    //var info = $scope.regCompany;
    var postdata = {
      companyName: $scope.inputCompanyInfo.companyName,
      divisionName: $scope.inputCompanyInfo.divisionName,
      staffName: $scope.inputCompanyInfo.staffName,
      email: $scope.inputCompanyInfo.email,
      options : $scope.optionServiceList,
      month: month,
      creditCardPayment : $scope.registForCorp  ? 0 : 1,
      meStaffName : meStaffName,
      packageType: $scope.selectPackage.type,
      //----
      emailSubject: '【つながるサイネージ】ご登録ありがとうございます',
      emailCreditcardStr: $scope.registForCorp ? '' : 'クレジットカードでの決済はまだ行われていません。',
      emailDemoDayStr: $scope.registForCorp ? '' : '日間、体験版として試用が行えます。',
      emailPaymentDaysStr: $scope.registForCorp ? '' : '日以内に決済処理を行ってください。',
      // _CREGITCARD_DISABLE_
      emailPaymentDisableStr: $scope.registForCorp ? '' : 'クレジットカードでの決済は現在停止しています。契約を希望される方は、当社までご連絡くださいますようお願いいたします。',
      // _CREGITCARD_DISABLE_
      emailLanguage: 'ja'  ,
      emailPackageName: $scope.selectPackage.type === 1 ? 'もっと！つながるサイネージ'　: 'つながるサイネージ'
    };
    var httpParam = {
      url: '/api/v1/company' + '?access_token=' + $scope.encToken,
      method: 'POST',
      data: postdata
    };

    $http(httpParam).success(function(result) {
      Auth.logout();
    });
  }

  //--------------------------------------------
  //ログインページに移る
  //--------------------------------------------
  $scope.endToRegister = function() {
    Auth.logout();
    $http({
      url: '/api/v1/creditcard/loginpage/' + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(json){
      $timeout(function(){
        $window.location.href=json.loginPage + 'logout' ;
      });
    })
    .error(function(data){
    });
  }

  //--------------------------------------------
  function checkInputInfo() {
    var msg = "";
    if (_.isUndefined( $scope.inputCompanyInfo.companyName )
        || $scope.inputCompanyInfo.companyName.length === 0 ) {
      msg += "\n - 組織の名称を入力してください";
    }
    if (_.isUndefined( $scope.inputCompanyInfo.email )
       || !$scope.MailCheck($scope.inputCompanyInfo.email ) ) {
      msg += "\n - エンドユーザの正しいメールアドレスを入力してください";
    }
    else {
      if ( $scope.inputCompanyInfo.email !== $scope.inputCompanyInfo.email_chk ) {
        msg += "\n - メールアドレスが一致しません";
      }
    }
    return msg;
  }

  // -------------------------------------------------------------------
  //emailが登録済みかどうかチェック
  // -------------------------------------------------------------------
  function isEmailExist(callback ) {
    $http({
      url: '/api/v1/company/info/get/'+ encodeURIComponent($scope.inputCompanyInfo.email) + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(data, status, headers, config){
      if (data.email === $scope.inputCompanyInfo.email ) {
        callback( true );
      }
      else {
        callback( false );
      }
    })
    .error(function(data, status, headers, config){
      callback( false );
    });
  }
    
  // -------------------------------------------------------------------
  // メールアドレスチェック関数
  // -------------------------------------------------------------------
  $scope.MailCheck = function ( mail ) {
    if (mail.length === 0 ) {
      return false;
    }
    var mail_regex1 = new RegExp( '(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*' );
    var mail_regex2 = new RegExp( '^[^\@]+\@[^\@]+$' );
    if( mail.match( mail_regex1 ) && mail.match( mail_regex2 ) ) {
        // 全角チェック
        if( mail.match( /[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/ ) ) { return false; }
        // 末尾TLDチェック（〜.co,jpなどの末尾ミスチェック用）
        if( !mail.match( /\.[a-z]+$/ ) ) { return false; }
        return true;
    } else {
        return false;
    }
  }
}]);

//===========================================
//
//
//RegisterCtrlの子：法人登録の場合のコントローラ
//
//
//===========================================
app.controller('RegisterCorporationCtrl', ['$scope', '$location', '$translate', 'Auth', '$http', '$window', '$timeout', '$controller', 'MediaedgeAdmin',
function ($scope, $location, $translate, Auth, $http, $window, $timeout, $controller, MediaedgeAdmin) {
  // メディアエッジ社内向けページでは、registIdがSessionStrageに設定されていること（電話番号を暗号化して使用）
  MediaedgeAdmin.checkRegistId($http, function(can, tokenResult) {
    if ( !can ) {
      $window.location.href="/company" ;
      return;  
    }
    else {
      $scope.encToken = encodeURIComponent(tokenResult.token);
      $scope.user = tokenResult.user
      getMediaedgeStaff();
    }
  });
  
  $controller('RegisterCtrl', {
                                $scope:$scope, 
                                $translate:$translate,
                                Auth:Auth,
                                $http:$http,
                                $location:$location, 
                                $window:$window,
                                $timeout:$timeout,
                                MediaedgeAdmin:MediaedgeAdmin }); //This works
  $scope.registForCorp = true;  //$controllerの中でfalse にされるので、後から設定すること

  $scope.sales = {
    name: '',
    divisionName: '',
    userName: '',
    email: '',
    email_chk: ''
  }

  $scope.selectYear = [];
  $scope.selectYear.push({value:1});
  $scope.selectYear.push({value:5});
  $scope.selectedYearChoise = _.cloneDeep($scope.selectYear[0]);
  exports.changeSelectYear = function() {
    
  }

  $scope.mediaedgeStaff = [];
  function getMediaedgeStaff() {
    $http({
      url: '/api/v1/company/mestaff/get/' + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(data){
      data.forEach(function(val){
        console.log(val);
        $scope.mediaedgeStaff.push({value:val.name} );
      });
      $scope.mediaedgeStaffChoise = [];//_.cloneDeep("");
    })
    .error(function(data){
    });
  }

  exports.changeMeStaffChoise = function() {

  }

  $scope.checkInputCorpInfo = function() {
    var msg = "";
    if (typeof $scope.sales.name === 'undefined'
        || $scope.sales.name.length === 0 ) {
      msg += "\n - 販社名を入力してください";
    }
    if ( _.isUndefined($scope.sales.email )
         || !$scope.MailCheck($scope.sales.email ) ) {
      msg += "\n - 販社担当者様の正しいメールアドレスを入力してください";
    }
    else {
      if ( $scope.sales.email !== $scope.sales.email_chk ) {
        msg += "\n - 販社担当者様のメールアドレスが一致しません";
      }
    }
    if (_.isUndefined( $scope.mediaedgeStaffChoise.value ) ) {
      msg += "\n - メディアエッジの営業担当者を選択してください";
    }
    return msg;
  }

}]);