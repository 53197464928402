var _selectOptionLibrary = function() {
    //転送量
    this.transferChoises = [{value:1},{value:2},{value:3},{value:4},{value:5}];
    this.selectedChoiseTransfer = _.cloneDeep(this.transferChoises[0]);
    //プレイリスト
    this.playlistChoises = [{value:1},{value:2},{value:3},{value:4},{value:5}];
    this.selectedChoisePlaylist = _.cloneDeep(this.playlistChoises[0]);
    //デバイスの契約数
    this. deviceChoises = [];
    for (var i =1; i <=100; i++ ){
      this.deviceChoises.push({value:i});
    }
    this.selectedChoise = _.cloneDeep(this.deviceChoises[0]);

    // 自動課金の失敗時の処理
    this.additionalMsg = "";  //自動課金に失敗したとき、最初の一回（契約手数料の項目利用）で請求するメッセージ
    this.additionalErr = "";  //自動課金に失敗した時のエラーコードの表示メッセージ
  //- ===========================
  //- pugと連携するものは$scope側で定義
  //-  this.totalPrices = 0;       // 費用合計
  //-  this.initialPrices = 3000;  // 契約手数料
  //-  this.optionServiceList= undefined;
};

_selectOptionLibrary.prototype.getInitialPrices = function () { return 3000; } //契約手数料（固定）
_selectOptionLibrary.prototype.getChangePrices = function () { return 0; } //契約手数料（固定）

//
//
//  callbackの中で、thisは参照できなかったので、$scope.optlib.を経由して自分自身の変数にアクセスしている
//
//
_selectOptionLibrary.prototype.changeDeviceChoise = function ($scope, optName, optChecked ) {
  $scope.totalPrices = 0;
    _.forEach($scope.optionServiceList, function(list){
      if (list.checked){
        if (list.perDevice) {
          if (list.perDevice === 1) {
            list.priceTotal = $scope.optlib.selectedChoise.value * list.price;
            list.amount = $scope.optlib.selectedChoise.value;
          } else {
            //50台単位の場合、50台は1契約とするため、-1 して計算する
            const calcval = $scope.optlib.selectedChoise.value == 0 ? 0 : $scope.optlib.selectedChoise.value - 1;
            var perDevice2 = parseInt(calcval / list.perDevice) + 1;  //小数切り捨て
            list.priceTotal = perDevice2 * list.price;
            list.amount = perDevice2;
          }

        } else {
          list.priceTotal = list.price;
          if (list.name === 'DataTransferAmount1G') {
            var transfer = $scope.optlib.selectedChoiseTransfer.value;
            list.priceTotal = transfer * list.price;
            list.amount = transfer
          }
          else if (list.name === 'AddPlaylist') {
            var playlist = $scope.optlib.selectedChoisePlaylist.value;
            list.priceTotal = playlist * list.price;
            list.amount = playlist
          }
          else {
            list.amount = 1;
          }
        }
        $scope.totalPrices += list.priceTotal;
      } else {
        list.priceTotal = undefined;
        list.amount = 0;
      }
      if ( optName !== null ) {
        $scope.optlib.checkCombination($scope, list, optName, optChecked);
      }
    });
  }

  _selectOptionLibrary.prototype.checkCombination = function ( $scope, list, optName, optChecked) {
    const Movie = 'Movie';
    const Still_and_Video = 'Still_and_Video';
    const support_mail = 'Support_By_Mail';
    const support_tel = 'Support_By_Tel_and_Mail';

    // 静止画動画混在は、動画オプションが必要
    if(optName === Movie) {
      if(!optChecked) {
        _.find($scope.optionServiceList, function(info){
          if ( info.name === Still_and_Video) {
            info.checked = false;
            return true;
          }
        });
      }
    }
    else if(optName === Still_and_Video) {
      if(optChecked) {
        _.find($scope.optionServiceList, function(info){
          if ( info.name === Movie) {
            info.checked = true;
            return true;
          }
        });
      }
    }

    //サポートオプションは排他
    else if(optName === support_mail) {
      if (optChecked){
        _.find($scope.optionServiceList, function(info){
          if ( info.name === support_tel) {
            info.checked = false;
            return true;
          }
        });
      }
    }
    else if(optName === support_tel) {
      if (optChecked){
        _.find($scope.optionServiceList, function(info){
          if ( info.name === support_mail) {
            info.checked = false;
            return true;
          }
        });
      }
    }
  }

  _selectOptionLibrary.prototype.getListOfOptionService = function( $scope, $http, $timeout, encToken, callback ) {
    $http.get('/api/v1/option-service' + '?access_token=' + encToken ).
    success(function(datas) {
      $scope.optionServiceList = datas;
      _.forEach($scope.optionServiceList, function(list){
        if (list.name === 'base') {
          list.checked = true;
          list.priceTotal = $scope.optlib.selectedChoise.value * list.price;
          list.amount = $scope.optlib.selectedChoise.value;
          $scope.totalPrices = 100;
        }
        else {
          list.checked = false;
        }
      });
      $timeout(function() {
        $scope.optlib.updateOptionServiceInfo($scope, $http, encToken, function () {
          callback ();
        });
      });
    });
  }
  _selectOptionLibrary.prototype.getListOfOptionServiceFromCompanyId = function( $scope, $http, $timeout, encToken, companyId, callback ) {
    $http.get('/api/v1/option-service' + '?access_token=' + encToken ).
    success(function(datas) {
      $scope.optionServiceList = datas;
      _.forEach($scope.optionServiceList, function(list){
        if (list.name === 'base') {
          list.checked = true;
          list.priceTotal = $scope.optlib.selectedChoise.value * list.price;
          list.amount = $scope.optlib.selectedChoise.value;
          $scope.totalPrices = 100;
        }
        else {
          list.checked = false;
        }
      });
      $timeout(function() {
        $scope.optlib.updateOptionServiceInfoFromId($scope, $http, encToken, companyId, function () {
          callback ();
        });
      });
    });
  }
  _selectOptionLibrary.prototype.updateOptionServiceInfo = function ( $scope, $http, encToken, callback ) {
    //ログイン情報
    $scope.userinfo = null;
    $http.get('/api/v1/users/me' + '?access_token=' + encToken )
    .success(function(datas) {
      $scope.userinfo=datas;
      //登録済みか
      $scope.optlib.optionContractionInfo = null; //契約情報
      const companyId = $scope.companyId ;
      if (companyId === null || _.isUndefined(companyId ) ) {
        callback();
      }
      else {
        $http.get('/api/v1/option-service/' + companyId + '?access_token=' + encToken).
        success(function(datas) {
          $scope.optlib.optionContractionInfo = datas; //契約情報
          _.forEach($scope.optionServiceList, function(list){
            _.find($scope.optlib.optionContractionInfo, function(info){
              if (info.optionId === list.optionServiceId) {
                if (list.name === 'base') {
                  $scope.optlib.selectedChoise.value = info.amount;
                }
                else if (list.name === 'DataTransferAmount1G') {
                  $scope.optlib.selectedChoiseTransfer.value = info.amount;
                }
                else if (list.name === 'AddPlaylist') {
                  $scope.optlib.selectedChoisePlaylist.value = info.amount;
                }
                return true;
              }
            });
          });
          $scope.optlib.UpdateCheckedList($scope);
          callback();
        }). error(function(){
          callback();
        });
      }
    }). error(function(){
      callback();
    });
  }
  _selectOptionLibrary.prototype.updateOptionServiceInfoFromId = function ( $scope, $http, encToken, companyId, callback ) {
    $http.get('/api/v1/option-service/' + companyId + '?access_token=' + encToken).
    success(function(datas) {
      $scope.optlib.optionContractionInfo = datas; //契約情報
      _.forEach($scope.optionServiceList, function(list){
        _.find($scope.optlib.optionContractionInfo, function(info){
          if (info.optionId === list.optionServiceId) {
            if (list.name === 'base') {
              $scope.optlib.selectedChoise.value = info.amount;
            }
            else if (list.name === 'DataTransferAmount1G') {
              $scope.optlib.selectedChoiseTransfer.value = info.amount;
            }
            else if (list.name === 'AddPlaylist') {
              $scope.optlib.selectedChoisePlaylist.value = info.amount;
            }
            return true;
          }
        });
      });
      $scope.optlib.UpdateCheckedList($scope);
      callback();
    }). error(function(){
      callback();
    });

  }

  _selectOptionLibrary.prototype.UpdateCheckedList = function ($scope) {
    _.forEach($scope.optionServiceList, function(list){
      var find = _.find($scope.optlib.optionContractionInfo, function(contra){
        return contra.optionId === list.optionServiceId ? true : false;
      });
      if (list.name === 'base') {
        list.checked = true;
      }
      else {
        list.checked = find ? true : false;
      }
    });
    $scope.optlib.changeDeviceChoise($scope, null, false);
  }

  _selectOptionLibrary.prototype.calcTotalPrice = function(priceOfMonth, selectedYearChoise ) {
    return priceOfMonth * 12 * selectedYearChoise.value;
  }
  _selectOptionLibrary.prototype.getTax = function( val ){
    return parseInt(val * 0.10);
  }
  _selectOptionLibrary.prototype.withTax = function( val ){
    return parseInt(val * 1.10);
  }

module.exports = _selectOptionLibrary