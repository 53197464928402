'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('userRegister', {
    url: '/register',
    templateUrl: 'app/company/register.html',
    authenticate: false,
    controller: 'RegisterCtrl',
    title: 'サービス登録（個人ユーザー様）'
  })
  .state('companyRegister', {
    url: '/company/register',
    templateUrl: 'app/company/register.html',
    controller: 'RegisterCorporationCtrl',
    authenticate: true,
    title: 'サービス登録（法人ユーザー様）'
  })
}]);
