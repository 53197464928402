'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('userLogin', {
    url: '/user',
    templateUrl: 'app/company/user/userLogin.html',
    authenticate: false,
    controller: 'UserLoginCtl',
    title: 'ユーザ登録'
  })
}]);
