'use strict';

var app = angular.module('limaApp');

app.factory('Modal', ['$rootScope', '$uibModal', '$locale', '$translate', '$sce', '$timeout',
function ($rootScope, $modal, $locale, $translate, $sce, $timeout) {
  /**
   * Opens a modal
   * @param  {Object} scope      - an object to be merged with modal's scope
   * @param  {String} modalClass - (optional) class(es) to be applied to the modal
   * @return {Object}            - the instance $modal.open() returns
   */
  function openModal(scope, modalClass) {
    var modalScope = $rootScope.$new();
    scope = scope || {};
    scope.trustAsHtml = scope.trustAsHtml || function (s) { return $sce.trustAsHtml(s); };
    modalClass = modalClass || 'modal-default';

    angular.extend(modalScope, scope);

    return $modal.open({
      templateUrl: 'components/modal/modal.html',
      windowClass: modalClass,
      scope: modalScope
    });
  }

  // Public API here
  return {

    /* Confirmation modals */
    confirm: {
      /**
       * Create a function to open a delete confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
       * @param  {Function} del - callback, ran when delete is confirmed
       * @return {Function}     - the function to open the modal (ex. myModalFn)
       */
      'delete': function(del) {
        del = del || angular.noop;

        /**
         * Open a delete confirmation modal
         * @param  {String} name   - name or info to show on modal
         * @param  {All}           - any additional args are passed staight to del callback
         */
        return function() {
          var args = Array.prototype.slice.call(arguments);
          var name = args.shift();
          var deleteModal;

          deleteModal = openModal({
            modal: {
              dismissable: true,
              title: $translate.instant('COMMON.CONFIRM_DELETE'),
              html: '<p>' + $translate.instant('COMMON.MSG.ARE_YOU_SURE_YOU_WANT_TO_DELETE', {name:name}) + '</p>',
              buttons: [{
                classes: 'btn-danger',
                text: $translate.instant('COMMON.DELETE'),
                click: function(e) {
                  deleteModal.close(e);
                }
              }, {
                classes: 'btn-default',
                text: $translate.instant('COMMON.CANCEL'),
                click: function(e) {
                  deleteModal.dismiss(e);
                }
              }]
            }
          }, 'modal-danger');

          deleteModal.result.then(function(event) {
            del.apply(event, args);
          });
        };
      },
      /**
       * open a yes/no confirmation modal
       * @param  {Object} opt - options to modify modal captions
       * @param  {Function} callback - callback, ran when clicked yes
       */
      yesno: function (opt, callback) {
        if (typeof opt == 'function') { callback = opt; opt = {}; }
        opt = opt || {};
        var scope = {
          modal: {
            dismissable: true,
            title: opt.title || $translate.instant('COMMON.CONFIRM'),
            html: opt.html || '<span>Yes/No?</span>',
            buttons: [{
              classes: opt.btnClassYes || 'btn-primary',
              text: opt.btnCaptionYes || 'Yes',
              click: function (e) { modal.close(e); }
            }, {
              classes: opt.btnClassNo || 'btn-default',
              text: opt.btnCaptionNo || 'No',
              click: function (e) { modal.dismiss(e); }
            }]
          }
        };
        if (opt.trustHtml) {
          scope.modal.trustedHtml = scope.modal.html;
          delete scope.modal.html;
        }
        var modal = openModal(scope, opt.modalClass || 'modal-primary');
        modal.result.then(function () {
          (callback || angular.noop)(true);
        }, function () {
          (callback || angular.noop)(false);
        });
      },
      /**
       * open a message modal
       * @param  {Object} opt - options to modify modal captions
       * @param  {Function} callback - callback, ran when closed the modal
       */
      message: function (opt, callback) {
        if (typeof opt == 'function') { callback = opt; opt = {}; }
        opt = opt || {};
        var scope = {
          modal: {
            dismissable: true,
            title: opt.title || $translate.instant('COMMON.CONFIRM'),
            html: opt.html || '',
            buttons: [{
              classes: opt.btnClassYes || 'btn-primary',
              text: opt.btnCaptionOK || $translate.instant('COMMON.OK'),
              click: function (e) { modal.close(e); }
            }]
          }
        };
        if (opt.trustHtml) {
          scope.modal.trustedHtml = scope.modal.html;
          delete scope.modal.html;
        }
        var modal = openModal(scope, opt.modalClass || 'modal-primary');
        modal.result.then(function () {
          (callback || angular.noop)(true);
        }, function () {
          (callback || angular.noop)(false);
        });
      }
    }
  };
}]);
