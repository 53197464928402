'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('toppage', {
    url: '/',
    templateUrl: 'app/toppage/toppage.html',
    authenticate: false,
    controller: 'ToppageCtl',
    title: ''
  })
}]);
