'use strict';

var app = angular.module('limaApp');

/*
*/
app.controller('UserLoginCtl', ['$scope', '$location', '$http', '$window', '$timeout', 'MediaedgeAdmin',
function ($scope, $location, $http, $window, $timeout, MediaedgeAdmin) {
  $scope.terms_url = "./resources/pdfjs/web/viewer.html?file=/resources/docs/terms_of_service_ja.pdf";

  $scope.pageType = 'userLoginInfo';
  $scope.terms_ok = false;
  $scope.encToken= "";
  $scope.user = {};
  const storage = sessionStorage;
  storage.clear();

  $scope.cancel = function() {
    //window.history.back();
  }

  $scope.toRegistPage = function() {
    MediaedgeAdmin.setRegistId($http, function(tokenResult ){
      $scope.encToken= encodeURIComponent(tokenResult.token);
      $scope.user = tokenResult.user
      $timeout(function(){
        $window.location.href='/register';
      });
    })  
  }

  $scope.toLoginPage = function() {
    $http({
      url: '/api/v1/creditcard/loginpage/' + '?access_token=' + $scope.encToken,
      method: 'GET',
    })
    .success(function(json){
      $timeout(function(){
        $window.location.href=json.loginPage ;
      });
    })
    .error(function(data){
    });  
  }
}]);
