'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('creditCardPayment', {
    url: '/ccpayment',
    templateUrl: 'app/creditcard/ccpayment.html',
    authenticate: true,
    controller: 'CreditCardPaymentCtl',
    title: 'クレジットカードの処理'
  })
}]);
