'use strict';

var app = angular.module('limaApp');

app.factory('socket', ['socketFactory', '$cookies', '$q', '$timeout', 'LimaRedirect',
function(socketFactory, $cookies, $q, $timeout, LimaRedirect) {
  var socket = null;
  var nextId = 0;
  var reqs = [];
  var handlers = [];
  function callRequest(options) {
    if (!socket) {
      var defer = $q.defer();
      defer.reject('not ready');
      return defer.promise;
    }
    var req = {id: nextId++, defer: $q.defer()};
    req.timeout = $timeout(function () {
      if (_.remove(reqs, req)[0] !== req) return;
      if (!req.defer) return;
      req.defer.reject('timeout');
    }, options.timeout || 30000);
    reqs.push(req);
    socket.emit('lima:' + options.call + ':' + options.name, {id: req.id, data:options.data});
    return req.defer.promise;
  }
  function callResponse(res) {
    var req = _.remove(reqs, {id:res.id})[0];
    if (!req) return;
    if (req.timeout) {
      $timeout.cancel(req.timeout);
      delete req.timeout;
    }
    if (!req.defer) return;
    if (res.err) {
      if (res.err.code === 401) {
        LimaRedirect.go({
          path: '/login',
          returnPath: true,
          help: 'Login session has expired',
          clearCache: true
        });
      }
      req.defer.reject(res.err);
    } else {
      req.defer.resolve(res.data);
    }
  }
  return {
    connect: function () {
      var defer = $q.defer();
      if (socket) {
        defer.resolve();
        return defer.promise;
      }
      var token = $cookies.get('token');
      if (!token) {
        defer.reject();
        return defer.promise;
      }
      // socket.io now auto-configures its connection when we ommit a connection url
      var ioSocket = io('', {
        // Send auth token on connection, you will need to DI the Auth service above
        query: 'token=' + token,
        path: '/socket.io-client'
      });
      socket = socketFactory({
        ioSocket: ioSocket
      });
      socket.on('lima:call-response', callResponse);
      _.forEach(handlers, function (handler) {
        if (_.isString(handler.name) && handler.name && _.isFunction(handler.fn))
          socket.on(handler.name, handler.fn);
      });
      defer.resolve();
      return defer.promise;
    },
    disconnect: function () {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    },
    scope: function (scope, name, fn) {
      if (!socket) return;
      socket.forward(name, scope);
      scope.$on('socket:' + name, fn);
    },
    on: function (name, fn) {
      handlers.push({name: name, fn: fn});
      if (socket) socket.on(name, fn);
    },
    emit: function (name, data) {
      this.connect().then(function () {
        socket.emit(name, data);
      });
    },
    call: function (name, data, timeout) {
      return callRequest({call:'call-request', name:name, data:data, timeout:timeout});
    },
    callIgnoreExpiration: function (name, data, timeout) {
      return callRequest({call:'call-request-ignore-expiration', name:name, data:data, timeout:timeout});
    },
    callTransactionStart: function (timeout) {
      return callRequest({call:'call-transaction', name:'start', timeout:timeout});
    },
    callTransactionCommit: function (timeout) {
      return callRequest({call:'call-transaction', name:'commit', timeout:timeout});
    },
    callTransactionRollback: function (timeout) {
      return callRequest({call:'call-transaction', name:'rollback', timeout:timeout});
    }
  };
}]);
